@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@600;700&family=Space+Mono:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Mega:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import-normalize;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.grayscale {
  filter: grayscale(100%);
}

html {
  overflow-x: hidden;
}
@media (min-width: 768px) {
  html::-webkit-scrollbar {
    width: 10px;
  }
  html::-webkit-scrollbar-thumb {
    background-color: #7b62e2;
  }
  html::-webkit-scrollbar-track {
    background-color: #4a535f;
  }

  .custom-scrollbar::-webkit-scrollbar {
    background-color: #181d23;
    width: 3px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4a535f;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #58616f;
  }
}

.article:hover {
  box-shadow: inset 0 0 0 2px #4a535f;
}
.article:hover > div > img {
  box-shadow: 0 0 0 3px #4a535f;
}
